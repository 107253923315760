@import 'vendor/vendor'

[hand],
.hand
  cursor: pointer

.gallery-item
  height: 300px
   /* Set a fixed height for the gallery items */
  @media (min-width: 992px)
    height: 150px

  .gallery-img
    object-fit: cover
     /* Ensures the image covers the entire column */
    height: 100%
     /* Makes the image fill the height of the column */
    width: 100%
     /* Makes the image fill the width of the column */

.no-hover-shadow
  transition: box-shadow 150ms ease-in-out
  // border: 3px solid color-mix(in srgb, var(--default-color), transparent 60%)
  &:hover
    box-shadow: none !important
    // border: 3px solid color-mix(in srgb, var(--accent-color), transparent 60%)

/*! ******************************************
 * CONTACT FORM
 ****************************************** */
.cl-contact-heading
  color: var(--accent-color)

.cl-contact-form
  transition: box-shadow 300ms ease-in-out
  background-color: var(--surface-color)
  height: 100%
  padding: 2em
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)

  .form-control
    padding: 0.75em 1em
    font-size: 0.9em
    resize: none

    &::placeholder
      color: var(--cl-gray-500)

    &:focus,
    &:focus-visible,
    &:focus-within
      box-shadow: none
      border: var(--cl-border-width) solid var(--accent-color)

  .btn[type="submit"]
    font-weight: 500
    color: var(--contrast-color)
    background: var(--accent-color)
